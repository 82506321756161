import React from "react"
import { graphql } from "gatsby"
import { Layout} from "@components/Layout"
import { SEO, mapToSEO} from '@components/SEO'
import { mapToPageHeader, Banner } from "@components/Banner"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { navigate } from "gatsby"
import styled from '@emotion/styled';
import mq from '@styles/mq'

const SubscriptionCentreIFrame = styled.iframe`
    width: 100%;
    border: none;
    margin-top: -49px;
    ${
      mq({
        height: ['123vw;', '100%;', '100%;']
      })
    }
`

const SubscriptionCentreLogoCover = styled.div`
    background-color: white;
    position: absolute;
    width: 100%;
    height: 15px;
    z-index: 1;
`

interface PreferenceProps {
  data: any;
}

interface PreferenceState {
   mcId: string
}

class Preferences extends React.Component<PreferenceProps, PreferenceState>{

  constructor(props:PreferenceProps){
    super(props);
    this.state = {
        mcId: ''
    }
  }

  loadFromQueryString = () => {
    if(location.search){
      let qs = this.getQueryStringParam(location.search, 'qs');
      let url = this.getQueryStringParam(location.search, 'url');
      if(qs){
        this.setState({
          mcId: qs
        });
        return;
      }
      else if(url){
        qs = this.getQueryStringParam(url.split('?')[1], 'qs');
        if(qs){
          this.setState({
            mcId: qs
          });
          // do this to make the URL look more friendly
          navigate(`/preferences?qs=${qs}`);
        }
      }
    }
  }

  getQueryStringParam = (url: string, paramName: string) => {
    const queryStringParams = new URLSearchParams(url);
    if(queryStringParams.has(paramName)){
      const paramValue = queryStringParams.get(paramName);
      return paramValue ? paramValue : '';
    }
    return '';
  }

  componentDidMount = () => {
    this.loadFromQueryString();
  }

  render(){ 
    const {elements} = this.props.data.kontentItemPreferenceCentrePage;
    const metadata = mapToPageHeader(elements);
    const seoData = mapToSEO(elements);

    return (<Layout version="simple">
                <SEO {...seoData} />
                <SubscriptionCentreLogoCover></SubscriptionCentreLogoCover>
                {
                  this.state.mcId &&
                  <SubscriptionCentreIFrame id='iframe' src={`https://cl.s10.exct.net/subscription_center.aspx?qs=${this.state.mcId}`}></SubscriptionCentreIFrame>
                }
          </Layout>
      );
  }
}

export const query = graphql`
query {
  site {
    siteMetadata {
      apiUrl
    }
  }
  kontentItemPreferenceCentrePage {
      elements {
        seo__noindex {
          value {
            name
            codename
          }
        }
        seo__nofollow {
          value {
            name
            codename
          }
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        general_page_content__title {
          value
        }
        general_page_content__header_image {
          value {
            url
            description
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            name
            codename
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            name
            codename
          }
        }
        general_page_content__title_position{
          value{
            codename
          }
        }
        url {
          value
        }
      }
  }
}
`

export default Preferences
